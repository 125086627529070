<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- header -->
    <!-- <header id="header" class="header">
      <div class="header-section" style="background-color: #f5f9fc;">
        <div id="logoAndNav" class="container">
          <nav class="js-mega-menu navbar navbar-expand-lg pt-lg-4">
            <router-link :to="{ path: '/' }" class="navbar-brand" aria-label="apidays.hk">
              <img src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" alt="Logo">
            </router-link>
          </nav>
        </div>
      </div>
    </header> -->

    <div class="position-relative z-index-2 overflow-hidden" style="background-color: #f5f9fc;">

      <!-- Hero -->
      <div class="container space-bottom-2 space-top-3">
        <div class="row align-items-center">
          <div class="col-lg-7 mb-3 mb-lg-0">
            <div class="mb-4">
              <span class="d-block small font-weight-bold text-cap mb-2">The Biggest API Ecosystem Conference in Hong Kong</span>
              <h1
                class="mb-4"
                style="font-weight:800;"
              >The API Landscape of Hong Kong</h1>
              <p>Apidays Connect Hong Kong 2023 is the world's leading series of API conferences, taking place on August 30 & 31. Don't miss out on this unique opportunity to learn from industry leaders and connect with other professionals!</p>
              <p><span class="font-weight-bold">Day 1</span> | Aug 30, 2023 | <a href="https://www.google.com/maps/place/Lakeside+2,+10+Science+Park+W+Ave,+Science+Park/@22.4267244,114.210722,17z/data=!3m1!4b1!4m6!3m5!1s0x34040897409caedf:0xe759bb6a4a3dc2cc!8m2!3d22.4267244!4d114.210722!16s%2Fg%2F12hs1qdk_?entry=ttu" target="_blank"><i class="fas fa-map-marker-alt mr-1" />Conference Halls 4-7, 2/F, Lakeside 2, Phase 2, Hong Kong Science Park</a></p>
              <p><span class="font-weight-bold">Day 2</span> | Aug 31, 2023 | Virtually via online platform</p>
              <!-- <p class="text-body mb-1">Aug 30, 2023 | Physically at Hong Kong Science Park (Hall 4-7)</p>
                <p class="text-body">Aug 31, 2023 | Virtually via online platform</p> -->
              <!-- Register Button -->
              <div class="mt-6">
                <router-link :to="{ path: '/' }" class="btn btn-primary btn-wide mr-2 mr-sm-3 mb-2">Speakers & Agenda</router-link>
                <a
                  class="js-go-to btn btn-outline-primary btn-white btn-wide mr-2 mr-sm-0 mb-2"
                  href="javascript:;"
                  data-hs-go-to-options="{
                  &quot;targetSelector&quot;: &quot;#ticket&quot;,
                  &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                  &quot;offsetTop&quot;: 0,
                  &quot;position&quot;: null,
                  &quot;animationIn&quot;: false,
                  &quot;animationOut&quot;: false
                  }"
                >View Coupon Codes
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/2021/images/hongkong.jpg') + ')'}" />
          </div>
        </div>
      </div>

      <!-- Coupon code -->
      <div id="ticket" style="background-color: #ffffff">
        <div class="container space-2">
          <div class="position-relative w-lg-85 mx-lg-auto mb-4">
            <div class="card shadow">
              <div class="card-body p-6">
                <div class="text-center mb-4">
                  <h2 class="mb-4">Special Offering for HKUST EC</h2>
                  <p class="mb-4">Find out the limited coupon codes for HKUST EC members, and claim your free ticket and NFT gift today!</p>
                </div>
                <!--
                <div class="row mb-6 align-items-center">
                  <div class="col-md-2 mb-2 mb-md-0">
                    <img class="img-fluid max-w-13rem" src="@/assets/img/apidays-2023/tickets/api-warrior-gold.jpg">
                  </div>
                  <div class="col-md-10">
                    <h4>Day 1 Full Day Pass + Day 2 Virtual Pass</h4>
                    <div class="input-group mb-1">
                      <input
                        ref="goldClone"
                        readonly
                        value="APIHKUSTECPT"
                        class="form-control bg-white"
                        @focus="$event.target.select()"
                      >
                      <div class="input-group-append">
                        <button class="btn btn-block btn-primary" @click="goldCopy">Copy</button>
                      </div>
                    </div>
                    <span class="text-body">5 quotas only. First come, first served.</span>
                  </div>
                </div>

                <hr>
                -->
                <div class="row my-6 align-items-center">
                  <div class="col-md-2 mb-2 mb-md-0">
                    <img class="img-fluid max-w-13rem" src="@/assets/img/apidays-2023/tickets/api-warrior-silver.jpg">
                  </div>
                  <div class="col-md-10">
                    <h4>Silver NFT - Day 1 Morning Pass + Day 2 Virtual Pass</h4>
                    <div class="input-group mb-1">
                      <input
                        ref="silverAMClone"
                        readonly
                        value="APIHKUSTECAMPT"
                        class="form-control bg-white"
                        @focus="$event.target.select()"
                      >
                      <div class="input-group-append">
                        <button class="btn btn-block btn-primary" @click="silverAMCopy">Copy</button>
                      </div>
                    </div>
                    <span class="text-body">*10 quotas only. First come, first served.</span>
                  </div>
                </div>

                <hr>

                <div class="row my-6 align-items-center">
                  <div class="col-md-2 mb-2 mb-md-0">
                    <img class="img-fluid max-w-13rem" src="@/assets/img/apidays-2023/tickets/api-warrior-silver.jpg">
                  </div>
                  <div class="col-md-10">
                    <h4>Silver NFT - Day 1 Afternoon Pass + Day 2 Virtual Pass</h4>
                    <div class="input-group mb-1">
                      <input
                        ref="silverPMClone"
                        readonly
                        value="APIHKUSTECPMPT"
                        class="form-control bg-white"
                        @focus="$event.target.select()"
                      >
                      <div class="input-group-append">
                        <button class="btn btn-block btn-primary" @click="silverPMCopy">Copy</button>
                      </div>
                    </div>
                    <span class="text-body">*10 quotas only. First come, first served.</span>
                  </div>
                </div>

                <p class="small mt-2"><i class="far fa-clock mr-1" /> All of the codes will be expired on <span class="font-weight-bold">11 August 2023</span></p>
                <div class="text-center mt-5">
                  <a class="btn btn-primary" href="https://app.moongate.id/events/apidays2023?utm_source=hkustec" target="blank">Claim Ticket and NFT Gift Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Discount -->
      <div class="">
        <div class="container space-2">
          <div class="row align-items-lg-center">
            <div class="col-lg-5 mb-3 mb-lg-0">
              <div class="w-60 w-lg-50 mx-auto">
                <img class="img-fluid" src="@/assets/svg/illustrations/giftbox.svg">
              </div>
            </div>
            <div class="col-lg-7">
              <div class="mb-4">
                <h2>All the Coupon Codes gone?</h2>
                <h3>Special 75% off Discount Code for All HKUST EC Members</h3>
                <p>Apply the following discount code to receive a 75% discount on all ticket types!</p>
              </div>
              <div class="">
                <div class="input-group">
                  <input
                    ref="discountClone"
                    readonly
                    value="APID23HKUSTECPT"
                    class="form-control bg-white"
                    @focus="$event.target.select()"
                  >
                  <div class="input-group-append">
                    <button class="btn btn-block btn-primary" @click="discountCopy">Copy</button>
                  </div>
                </div>
                <p class="small mt-2"><i class="far fa-clock mr-1" /> Expire on <span class="font-weight-bold">11 August 2023</span></p>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- How to Redeem? -->
      <div style="background-color: #ffffff">
        <div class="container space-2">
          <div class="text-center">
            <h2 class="mb-6">How to Redeem?</h2>
          </div>
          <div class="row">
            <div v-for="(item, index) in Steps" :key="index" class="col-md-3 mb-4 mb-md-0">
              <div class="text-center">
                <figure class="max-w-10rem mx-auto mb-3">
                  <img class="img-fluid" :src="require('@/assets/svg/icons/' + item.icon)">
                </figure>
                <h3>{{ item.title }}</h3>
                <p v-html="item.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '../../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../../assets/js/hs.core.js'
import 'bootstrap'

export default {
  name: 'CouponHKUSTEC',
  data() {
    return {
      Steps: [
        {
          title: 'Visit Ticket System',
          description: 'Visit <a class="text-primary font-weight-bold link-underline" href="https://app.moongate.id/events/apidays2023?utm_source=hkustec" target="_blank">here</a> and click "Get Ticket"',
          icon: 'icon-25.svg'
        },
        {
          title: 'Enter Discount Code',
          description: 'Apply the discount code and then select the amount of ticket',
          icon: 'icon-28.svg'
        },
        {
          title: 'Fill in Checkout Information',
          description: 'Fill in attendees\' information and checkout',
          icon: 'phase-1.svg'
        },
        {
          title: 'NFT Ticket Ready',
          description: 'Congratulations! You will receive your NFT ticket via email and are now ready to access the conference',
          icon: 'icon-15.svg'
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  methods: {
    goldCopy() {
      this.$refs.goldClone.focus()
      document.execCommand('copy')
    },
    silverAMCopy() {
      this.$refs.silverAMClone.focus()
      document.execCommand('copy')
    },
    silverPMCopy() {
      this.$refs.silverPMClone.focus()
      document.execCommand('copy')
    },
    discountCopy() {
      this.$refs.discountClone.focus()
      document.execCommand('copy')
    }
  },
  metaInfo() {
    return {
      title: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023',
      titleTemplate: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023',
      link: [
        { rel: 'canonical', href: 'https://www.apidays.hk/coupon/hkust-ec' }
      ],
      meta: [
        { name: 'description', content: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023' },
        { property: 'og:site_name', content: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023' },
        { property: 'og:title', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: '30-31 Aug, 2023 - The API Landscape of Hong Kong | apidays Connect Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/coupon/hkust-ec' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>

